import React from 'react';
import { BrowserRouter, HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import loadable from './loadable';
import { connect } from 'react-redux';
import axios from 'axios';
const AppPage = loadable(() => import('../pages/app.js'));
const LoginPage = loadable(() => import('../pages/loginPage'));
const HomePage = loadable(() => import('../pages/homePage'));
const CasePage = loadable(() => import('../pages/casePage'));
const CaseDetailPage = loadable(() => import('../pages/caseDetailPage'));
const CaseSharePage = loadable(() => import('../pages/caseSharePage'));
const AccountOptionPage = loadable(() => import('../pages/accountOptionPage'));
function userLogin() {
  let userInfo = JSON.parse(localStorage.getItem('userInfo'));
  if (userInfo !== null) {
    return true;
  } else if (window.dd.env.platform !== 'notInDingTalk') {
    return true;
  }
}

// 登录验证
function requireAuth(Layout, props) {
  let res = userLogin();
  if (!res) {
    // 未登录
    return <Redirect to="/login" />;
  } else {
    return <Layout {...props} />;
  }
}
const BasicRoute = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/login" component={LoginPage} />
      <Route exact path="/case" component={(props) => requireAuth(CasePage, props)} />
      <Route path="/account" component={(props) => requireAuth(AccountOptionPage, props)} />
      <Route exact path="/caseDetail/:id" component={(props) => requireAuth(CaseDetailPage, props)} />
      {/* <Route exact path="/caseDetail/:id" component={(props) => requireAuth(CaseDetailPage, props)} /> */}
      <Route path="/share/:id" component={CaseSharePage} />
      <Route exact path="/" component={CasePage} />
      {/* <Route  path="/" component={ HomePage } /> */}
    </Switch>
  </BrowserRouter>
);
export default BasicRoute;
